import React from "react";
import Helmet from "react-helmet";

import "../assets/scss/main.scss";
import "../assets/scss/appstore.scss";

import logo from '../assets/images/dictationmate/logo.png'
class AppPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          loading: 'is-loading'
        }
      }
  
      componentDidMount () {
        this.timeoutId = setTimeout(() => {
            this.setState({loading: ''});
        }, 100);
      }
  
      componentWillUnmount () {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
    }
          
    render() {
        const { children } = this.props;
        const siteTitle = "Dictationmate";

        return (
        <div className={`body ${this.state.loading}`}>

            <Helmet title={siteTitle} />

            <section id="footer">
                <div className="inner">
                    <span><img src={logo} alt="" width="120px" height="120px"/></span>
                    <h1><strong>Dictationmate 默書好幫手</strong></h1>
                    <p>You can use Dictationmate to define your vocabulary and practise your phonic and dictation skill all by yourself.</p>
                </div>

                <p></p>

                <ul className="icons">
                    <li>
                        <a className="icon appStoreButton" href='https://play.google.com/store/apps/details?id=com.sumlook.dictationmate&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>
                        <a className="icon appStoreButton" href='https://apps.apple.com/us/app/dictationmate/id1541159451?itsct=apps_box&itscg=30200'><img alt='Get it on Apple App Store' src="/images/apple-app-store.png"/></a>
                        <a className="icon appStoreButton" href='https://appgallery.cloud.huawei.com/ag/n/app/C102983821?channelId=sumlook.com&referrer=sumlook.com&id=29d6aac4ce724354a741e117f6d2b640&s=A06A52662ECF0A91554C8C1917A910C611FA6B337BFC1C3BBC167ACF7B30C2FC&detailType=0&v='><img alt='Get it on Huawei AppGallery' src="/images/huawei-appgallery.png"/></a>
                    </li> 
                </ul>

                <ul className="copyright">
                    <li>© Sumlook Production</li>
                </ul>

            </section>


        </div>
        );
    }
}

export default AppPage;